@import '../resources/vars';
@import '../resources/mixins';

.container {
  background-color: var(--forms-segmented-btn-bg-colour);
  border: var(--forms-segmented-btn-border);
  border-radius: var(--forms-segmented-btn-border-radius);
  box-shadow: var(--forms-segmented-btn-box-shadow);
  padding: var(--forms-segmented-btn-padding);
  width: var(--forms-segmented-btn-width, unset);
  height: var(--forms-segmented-btn-height);
  flex-direction: var(--forms-segmented-btn-flex-direction, column);
  gap: var(--forms-segmented-btn-gap);
  display: flex;

  order: var(--forms-input-text-order-field);

  @include respond-to(sm, up) {
    height: var(--forms-segmented-btn-height-m);
    flex-direction: var(--forms-segmented-btn-flex-direction-m, row);
    gap: var(--forms-segmented-btn-gap-m);
  }

  @include respond-to(lg, up) {
    height: var(--forms-segmented-btn-height-l);
    flex-direction: var(--forms-segmented-btn-flex-direction-l, row);
    gap: var(--forms-segmented-btn-gap-l);
  }
}

.segment {
  align-items: center;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  height: var(--forms-segmented-btn-item-height, 48px);
  justify-content: center;
  min-width: 0;
  padding: var(--forms-segmented-btn-item-padding);

  @include respond-to(sm, up) {
    height: var(--forms-segmented-btn-item-height-m, 64px);
    flex-basis: 0;
  }

  @include respond-to(lg, up) {
    height: var(--forms-segmented-btn-item-height-l, 64px);
  }

  &:not(:first-child) {
    margin: var(--forms-segmented-btn-item-margin, 8px 0 0 0);

    @include respond-to(sm, up) {
      margin: var(--forms-segmented-btn-item-margin-m, 0 0 0 8px);
    }

    @include respond-to(lg, up) {
      margin: var(--forms-segmented-btn-item-margin-l, 0 0 0 16px);
    }
  }
}

.segment {
  background-color: var(--forms-segmented-btn-item-off-bg-colour);
  border: var(--forms-segmented-btn-item-off-border);
  border-radius: var(--forms-segmented-btn-item-off-inner-radius);
  box-shadow: var(--forms-segmented-btn-item-off-box-shadow);
  color: var(--forms-segmented-btn-item-off-label-colour);

  &:first-child {
    border-bottom-left-radius: var(--forms-segmented-btn-item-off-outer-radius);
    border-top-left-radius: var(--forms-segmented-btn-item-off-outer-radius);
  }

  &:last-child {
    border-bottom-right-radius: var(--forms-segmented-btn-item-off-outer-radius);
    border-top-right-radius: var(--forms-segmented-btn-item-off-outer-radius);
  }

  &.active {
    background-color: var(--forms-segmented-btn-item-on-bg-colour);
    border: var(--forms-segmented-btn-item-on-border);
    border-radius: var(--forms-segmented-btn-item-on-inner-radius);
    box-shadow: var(--forms-segmented-btn-item-on-box-shadow);
    color: var(--forms-segmented-btn-item-on-label-colour);

    &:first-child {
      border-bottom-left-radius: var(--forms-segmented-btn-item-on-outer-radius);
      border-top-left-radius: var(--forms-segmented-btn-item-on-outer-radius);
    }

    &:last-child {
      border-bottom-right-radius: var(--forms-segmented-btn-item-on-outer-radius);
      border-top-right-radius: var(--forms-segmented-btn-item-on-outer-radius);
    }
  }
}

.segment {
  font-family: var(--forms-segmented-btn-item-label-face);
  font-size: var(--forms-segmented-btn-item-label-size);
  font-style: var(--forms-segmented-btn-item-off-label-font-style);
  font-weight: var(--forms-segmented-btn-item-off-label-weight);
  letter-spacing: var(--forms-segmented-btn-item-off-label-letter-spacing);
  line-height: var(--forms-segmented-btn-item-off-label-line-height);
  text-transform: var(--forms-segmented-btn-item-off-label-text-transform);

  &.active {
    font-style: var(--forms-segmented-btn-item-on-label-font-style);
    font-weight: var(--forms-segmented-btn-item-on-label-weight);
    letter-spacing: var(--forms-segmented-btn-item-on-label-letter-spacing);
    line-height: var(--forms-segmented-btn-item-on-label-line-height);
    text-transform: var(--forms-segmented-btn-item-on-label-text-transform);
  }
}

.label {
  line-height: 1.2;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--forms-segmented-btn-wrapper-gap);
  padding: var(--forms-segmented-btn-wrapper-padding);

  @include respond-to(sm, up) {
    gap: var(--forms-segmented-btn-wrapper-gap-m);
    padding: var(--forms-segmented-btn-wrapper-padding-m);
  }

  @include respond-to(lg, up) {
    gap: var(--forms-segmented-btn-wrapper-gap-l);
    padding: var(--forms-segmented-btn-wrapper-padding-l);
  }
}

.input-label {
  @include with-styled-text(forms-segmented-label);
  margin: var(--forms-segmented-label-margin);
}
