@import '../resources/vars';
@import '../resources/mixins';

@mixin generate-opt-styles($i) {
  .wp-block-media-text.opt-#{$i} {
    --app-custom-mt-gap: var(--mt-opt#{$i}-gap);
    --app-custom-mt-gap-m: var(--mt-opt#{$i}-gap-m);
    --app-custom-mt-gap-l: var(--mt-opt#{$i}-gap-l);
    --app-custom-mt-flex-direction: var(--mt-opt#{$i}-flex-direction);
    --app-custom-mt-flex-media-right-direction: var(--mt-opt#{$i}-flex-media-right-direction);
    --app-custom-mt-flex-direction-m: var(--mt-opt#{$i}-flex-direction-m);
    --app-custom-mt-flex-direction-l: var(--mt-opt#{$i}-flex-direction-l);

    --app-custom-mt-small-flex-direction: var(--mt-opt#{$i}-small-flex-direction);
    --app-custom-mt-small-flex-direction-m: var(--mt-opt#{$i}-small-flex-direction-m);
    --app-custom-mt-small-flex-direction-l: var(--mt-opt#{$i}-small-flex-direction-l);

    --app-custom-mt-medium-flex-direction: var(--mt-opt#{$i}-medium-flex-direction);
    --app-custom-mt-medium-flex-direction-m: var(--mt-opt#{$i}-medium-flex-direction-m);
    --app-custom-mt-medium-flex-direction-l: var(--mt-opt#{$i}-medium-flex-direction-l);

    --app-custom-mt-large-flex-direction: var(--mt-opt#{$i}-large-flex-direction);
    --app-custom-mt-large-flex-direction-m: var(--mt-opt#{$i}-large-flex-direction-m);
    --app-custom-mt-large-flex-direction-l: var(--mt-opt#{$i}-large-flex-direction-l);
    --app-custom-mt-large-media-right-flex-direction: var(--mt-opt#{$i}-large-media-right-flex-direction);

    --app-custom-mt-margine: var(--mt-opt#{$i}-margin);
    --app-custom-mt-margin-m: var(--mt-opt#{$i}-margin-m);
    --app-custom-mt-margin-l: var(--mt-opt#{$i}-margin-l);

    //Text
    --app-custom-mt-text-background: var(--mt-text-opt#{$i}-background);

    --app-custom-mt-text-justify-content: var(--mt-text-opt#{$i}-justify-content);
    --app-custom-mt-text-justify-content-m: var(--mt-text-opt#{$i}-justify-content-m);
    --app-custom-mt-text-justify-content-l: var(--mt-text-opt#{$i}-justify-content-l);

    --app-custom-mt-text-padding: var(--mt-text-opt#{$i}-padding);
    --app-custom-mt-text-padding-m: var(--mt-text-opt#{$i}-padding-m);
    --app-custom-mt-text-padding-l: var(--mt-text-opt#{$i}-padding-l);

    --app-custom-mt-text-onright-padding: var(--mt-text-opt#{$i}-onright-padding);
    --app-custom-mt-text-onright-padding-m: var(--mt-text-opt#{$i}-onright-padding-m);
    --app-custom-mt-text-onright-padding-l: var(--mt-text-opt#{$i}-onright-padding-l);

    --app-custom-mt-text-heading-margin: var(--mt-text-opt#{$i}-heading-margin);
    --app-custom-mt-text-heading-margin-m: var(--mt-text-opt#{$i}-heading-margin-m);
    --app-custom-mt-text-heading-margin-l: var(--mt-text-opt#{$i}-heading-margin-l);

    --app-custom-mt-text-paragraph-text-align: var(--mt-text-opt#{$i}-paragraph-text-align);
    --app-custom-mt-text-paragraph-text-align-m: var(--mt-text-opt#{$i}-paragraph-text-align-m);
    --app-custom-mt-text-paragraph-text-align-l: var(--mt-text-opt#{$i}-paragraph-text-align-l);

    //Image
    --app-custom-mt-image-flex-basis: var(--mt-image-opt#{$i}-flex-basis);
    --app-custom-mt-image-flex-basis-m: var(--mt-image-opt#{$i}-flex-basis-m);
    --app-custom-mt-image-flex-basis-l: var(--mt-image-opt#{$i}-flex-basis-l);

    --app-custom-mt-image-small-height: var(--mt-image-opt#{$i}-height);
    --app-custom-mt-image-small-height-m: var(--mt-image-opt#{$i}-height-m);
    --app-custom-mt-image-small-height-l: var(--mt-image-opt#{$i}-height-l);

    --app-custom-mt-image-small-flex-basis: var(--mt-image-opt#{$i}-small-flex-basis);
    --app-custom-mt-image-small-flex-basis-m: var(--mt-image-opt#{$i}-small-flex-basis-m);
    --app-custom-mt-image-small-flex-basis-l: var(--mt-image-opt#{$i}-small-flex-basis-l);

    --app-custom-mt-image-medium-height: var(--mt-image-opt#{$i}-medium-height);
    --app-custom-mt-image-medium-height-m: var(--mt-image-opt#{$i}-medium-height-m);
    --app-custom-mt-image-medium-height-l: var(--mt-image-opt#{$i}-medium-height-l);

    --app-custom-mt-image-medium-flex-basis: var(--mt-image-opt#{$i}-medium-flex-basis);
    --app-custom-mt-image-medium-flex-basis-m: var(--mt-image-opt#{$i}-medium-flex-basis-m);
    --app-custom-mt-image-medium-flex-basis-l: var(--mt-image-opt#{$i}-medium-flex-basis-l);

    --app-custom-mt-image-large-height: var(--mt-image-opt#{$i}-large-height);
    --app-custom-mt-image-large-height-m: var(--mt-image-opt#{$i}-large-height-m);
    --app-custom-mt-image-large-height-l: var(--mt-image-opt#{$i}-large-height-l);

    --app-custom-mt-image-large-flex-basis: var(--mt-image-opt#{$i}-large-flex-basis);
    --app-custom-mt-image-large-flex-basis-m: var(--mt-image-opt#{$i}-large-flex-basis-m);
    --app-custom-mt-image-large-flex-basis-l: var(--mt-image-opt#{$i}-large-flex-basis-l);
  }
}

.container {
  background-color: var(--theme-template-bg-colour);
  flex: 1;

  @at-root :global(.sidebar) & {
    border-right: var(--sidebar-page-template-keyline);
  }
}

.component {
  margin: 0 auto;
  max-width: var(--app-cusome-layout-sub-width, var(--layout-sub-width));
}

.headline {
  display: flex;
  flex-direction: column;
  background: var(--app-template-heading-container-bg-colour, var(--page-template-heading-container-bg-colour));
  border: var(--app-template-heading-container-border, var(--page-template-heading-container-border));
  border-radius: var(
      --app-template-heading-container-border-radius,
      var(--page-template-heading-container-border-radius)
  );
  box-shadow: var(--app-template-heading-container-box-shadow, var(--page-template-heading-container-box-shadow));

  --default-template-heading-container-padding: var(
      --app-template-heading-container-padding,
      var(--page-template-heading-container-padding)
  );
  padding: var(--default-template-heading-container-padding);

  gap: var(--app-template-heading-container-gap, var(--page-template-heading-container-gap));
  margin: var(--app-template-heading-container-margin, var(--page-template-heading-container-margin));

  @include respond-to(sm, up) {
    padding: var(--page-template-heading-container-padding-m, var(--default-template-heading-container-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--page-template-heading-container-padding-l, var(--default-template-heading-container-padding));
  }
}

.title {
  margin: var(--app-template-heading-margin, var(--page-template-heading-margin));
  padding: var(--app-template-heading-padding, var(--page-template-heading-padding));

  color: var(--app-template-heading-colour, var(--page-template-heading-colour));
  font-family: var(--app-template-heading-font-face, var(--page-template-heading-font-face));
  font-style: var(--app-template-heading-font-style, var(--page-template-heading-font-style));
  font-weight: var(--app-template-heading-font-weight, var(--page-template-heading-font-weight));
  letter-spacing: var(--app-template-heading-letter-spacing, var(--page-template-heading-letter-spacing));
  line-height: var(--app-template-heading-line-height, var(--page-template-heading-line-height));
  text-align: var(--app-template-heading-text-align, var(--page-template-heading-text-align));
  text-transform: var(--app-template-heading-text-transform, var(--page-template-heading-text-transform));

  --default-template-heading-font-size: var(--app-template-heading-font-size, var(--page-template-heading-font-size));
  font-size: var(--default-template-heading-font-size);

  @include respond-to(sm, up) {
    font-size: var(--page-template-heading-font-size-m, var(--default-template-heading-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--page-template-heading-font-size-l, var(--default-template-heading-font-size));
  }
}

.subtitle {
  margin: var(--app-template-par-heading-margin, var(--page-template-par-heading-margin));
  padding: var(--app-template-par-heading-padding, var(--page-template-par-heading-padding));

  color: var(--app-template-par-heading-colour, var(--page-template-par-heading-colour));
  font-family: var(--app-template-par-heading-font-face, var(--page-template-par-heading-font-face));
  font-size: var(--app-template-par-heading-font-size, var(--page-template-par-heading-font-size));
  font-style: var(--app-template-par-heading-font-style, var(--page-template-par-heading-font-style));
  font-weight: var(--app-template-par-heading-font-weight, var(--page-template-par-heading-font-weight));
  letter-spacing: var(--app-template-par-heading-letter-spacing, var(--page-template-par-heading-letter-spacing));
  line-height: var(--app-template-par-heading-line-height, var(--page-template-par-heading-line-height));
  text-align: var(--app-template-par-heading-text-align, var(--page-template-par-heading-text-align));
  text-transform: var(--app-template-par-heading-text-transform, var(--page-template-par-heading-text-transform));
}

.image {
  overflow: hidden;
}

// TODO: Refactor it!!!
/* stylelint-disable */
.body {
  overflow-wrap: break-word;

  padding: var(--article-body-padding);

  @include respond-to(sm, up) {
    padding: var(--article-body-padding-m, var(--article-body-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--article-body-padding-l, var(--article-body-padding));
  }

  :global {
    .space-content {
      @at-root :global(.landing) & {
        margin-bottom: var(--template-landing-spacer-content-s) !important;

        @include respond-to(sm, up) {
          margin-bottom: var(--template-landing-spacer-content-ml) !important;
        }
      }
    }

    .space-component {
      @at-root :global(.landing) & {
        margin-bottom: var(--template-landing-spacer-components-s) !important;

        @include respond-to(sm, up) {
          margin-bottom: var(--template-landing-spacer-content-ml) !important;
        }
      }
    }

    .space-section {
      @at-root :global(.landing) & {
        margin-bottom: var(--template-landing-spacer-sections-s) !important;

        @include respond-to(sm, up) {
          margin-bottom: var(--template-landing-spacer-content-ml) !important;
        }
      }
    }

    @for $i from 1 through 2 {
      @include generate-opt-styles($i);
    }

    .wp-block-media-text {
      display: flex;
      flex: 0 1 auto;
      flex-direction: var(--app-custom-mt-flex-direction, var(--mt-flex-direction, column-reverse));
      justify-content: center;
      margin: var(--app-custom-mt-margin, var(--mt-margin));
      gap: var(--app-custom-mt-gap, var(--mt-gap));

      @include respond-to(sm, up) {
        flex-direction: var(--app-custom-mt-flex-direction-m, var(--mt-flex-direction-m, row));
        margin: var(--app-custom-mt-margin-m, var(--mt-margin-m));
        gap: var(--app-custom-mt-gap-m, var(--mt-gap-m));
      }

      @include respond-to(lg, up) {
        flex-direction: var(--app-custom-mt-flex-direction-l, var(--mt-flex-direction-l, row));
        margin: var(--app-custom-mt-margin-l, var(--mt-margin-l));
        gap: var(--app-custom-mt-gap-l, var(--mt-gap-l));
      }

      &__media {
        flex-basis: var(--app-custom-mt-image-flex-basis, var(--mt-image-flex-basis, 50%));

        @include respond-to(sm, up) {
          flex-basis: var(--app-custom-mt-image-flex-basis-m, var(--mt-image-flex-basis-m, 50%));
        }

        @include respond-to(lg, up) {
          flex-basis: var(--app-custom-mt-image-flex-basis-l, var(--mt-image-flex-basis-l, 50%));
        }

        align-self: center;

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: var(--app-custom-mt-text-justify-content, var(--mt-text-justify-content, center));
        padding: var(--app-custom-mt-text-padding, var(--mt-text-padding));
        background: var(--app-custom-mt-text-background, var(--mt-text-background));

        @include respond-to(sm, up) {
          justify-content: var(--app-custom-mt-text-justify-content-m, var(--mt-text-justify-content-m));
          padding: var(--app-custom-mt-text-padding-m, var(--mt-text-padding-m));
        }

        @include respond-to(lg, up) {
          justify-content: var(--app-custom-mt-text-justify-content-l, var(--mt-text-justify-content-l));
          padding: var(--app-custom-mt-text-padding-l, var(--mt-text-padding-l));
        }

        @at-root :global(.landing) & {
          > * {
            margin-bottom: var(--template-landing-spacer-content-s);

            @include respond-to(sm, up) {
              margin-bottom: var(--template-landing-spacer-content-ml);
            }
          }
        }

        p {
          padding-bottom: 0;
          text-align: var(--app-custom-mt-text-paragraph-text-align, var(--mt-text-paragraph-text-align));

          @include respond-to(sm, up) {
            text-align: var(--app-custom-mt-text-paragraph-text-align-m, var(--mt-text-paragraph-text-align-m));
          }

          @include respond-to(lg, up) {
            text-align: var(--app-custom-mt-text-paragraph-text-align-l, var(--mt-text-paragraph-text-align-l));
          }
        }

        .wp-block-heading {
          margin: var(--app-custom-mt-text-heading-margin, var(--mt-text-heading-margin));

          @include respond-to(sm, up) {
            margin: var(--app-custom-mt-text-heading-margin-m, var(--mt-text-heading-margin-m));
          }

          @include respond-to(lg, up) {
            margin: var(--app-custom-mt-text-heading-margin-l, var(--mt-text-heading-margin-l));
          }
        }
      }

      // This is a list of ware to define the behaviour of the items (image and text) inside .wp-block-media-text
      &.promo-large {
        flex-wrap: wrap;
        --app-custom-mt-flex-direction: var(--app-custom-mt-large-flex-direction, var(--mt-large-flex-direction));
        --app-custom-mt-flex-direction-m: var(--app-custom-mt-large-flex-direction-m, var(--mt-large-flex-direction-m));
        --app-custom-mt-flex-direction-l: var(--app-custom-mt-large-flex-direction-l, var(--mt-large-flex-direction-l));

        &.has-media-on-the-right{
          --app-custom-mt-flex-direction: var(--app-custom-mt-large-media-right-flex-direction, var(--mt-large-media-right-flex-direction));
        }
      }

      &.promo-small{
        --app-custom-mt-flex-direction: var(--app-custom-mt-small-flex-direction, var(--mt-small-flex-direction));
        --app-custom-mt-flex-direction-m: var(--app-custom-mt-small-flex-direction-m, var(--mt-small-flex-direction-m));
        --app-custom-mt-flex-direction-l: var(--app-custom-mt-small-flex-direction-l, var(--mt-small-flex-direction-l));
      }

      &.promo-medium{
        --app-custom-mt-flex-direction: var(--app-custom-mt-medium-flex-direction, var(--mt-medium-flex-direction));
        --app-custom-mt-flex-direction-m: var(--app-custom-mt-medium-flex-direction-m, var(--mt-medium-flex-direction-m));
        --app-custom-mt-flex-direction-l: var(--app-custom-mt-medium-flex-direction-l, var(--mt-medium-flex-direction-l));
      }
    }


    .has-media-on-the-right {
      --app-custom-mt-flex-direction: var(--app-custom-mt-flex-media-right-direction,--mt-flex-media-right-direction);
    }

    .has-media-on-the-right .wp-block-media-text {

      &__content {
        padding: var(--app-custom-mt-text-onright-padding, var(--mt-text-onright-padding));

        @include respond-to(sm, up) {
          padding: var(--app-custom-mt-text-onright-padding-m, var(--mt-text-onright-padding-m));
        }

        @include respond-to(lg, up) {
          padding: var(--app-custom-mt-text-onright-padding-l, var(--mt-text-onright-padding-l));
        }
      }
    }

    .promo-small .wp-block-media-text {
      &__media  {
        --app-custom-mt-image-flex-basis: var(--app-custom-mt-image-small-flex-basis, var(--mt-image-small-flex-basis, 40px));
        --app-custom-mt-image-flex-basis-m: var(--app-custom-mt-image-small-flex-basis-m, var(--mt-image-small-flex-basis-m, 40px));
        --app-custom-mt-image-flex-basis-l: var(--app-custom-mt-image-small-flex-basis-l, var(--mt-image-small-flex-basis-l, 40px));
        height: var(--app-custom-mt-image-small-height, var(--mt-image-small-height, 80px));
        @include respond-to(sm, up) {
          height: var(--app-custom-mt-image-small-height-m, var(--mt-image-small-height-m, 80px));
        }
        @include respond-to(lg, up) {
          height: var(--app-custom-mt-image-small-height-l, var(--mt-image-small-height-l, 80px));
        }
      }
    }

    .promo-medium .wp-block-media-text {
      &__media  {
        --app-custom-mt-image-flex-basis: var(--app-custom-mt-image-medium-flex-basis, var(--mt-image-medium-flex-basis, 128px));
        --app-custom-mt-image-flex-basis-m: var(--app-custom-mt-image-medium-flex-basis-m, var(--mt-image-medium-flex-basis-m, 128px));
        --app-custom-mt-image-flex-basis-l: var(--app-custom-mt-image-medium-flex-basis-l, var(--mt-image-medium-flex-basis-l, 128px));
        height: var(--app-custom-mt-image-medium-height, var(--mt-image-medium-height, 140px));
        @include respond-to(sm, up) {
          height: var(--app-custom-mt-image-medium-height-m, var(--mt-image-medium-height-m, 140px));
        }
        @include respond-to(lg, up) {
          height: var(--app-custom-mt-image-medium-height-l, var(--mt-image-medium-height-l, 140px));
        }
      }
    }

    .promo-large .wp-block-media-text {
      &__media  {
        --app-custom-mt-image-flex-basis: var(--app-custom-mt-image-large-flex-basis, var(--mt-image-large-flex-basis, 288px));
        --app-custom-mt-image-flex-basis-m: var(--app-custom-mt-image-large-flex-basis-m, var(--mt-image-large-flex-basis-m, 288px));
        --app-custom-mt-image-flex-basis-l: var(--app-custom-mt-image-large-flex-basis-l, var(--mt-image-large-flex-basis-l, 288px));
        height: var(--app-custom-mt-image-large-height, var(--mt-image-large-height, 288px));
        @include respond-to(sm, up) {
          height: var(--app-custom-mt-image-large-height-m, var(--mt-image-large-height-m, 288px));
        }
        @include respond-to(lg, up) {
          height: var(--app-custom-mt-image-large-height-l, var(--mt-image-large-height-l, 288px));
        }
      }
    }

    .wp-block-image {
      figcaption {
        padding: var(--image-caption-padding);
        margin: var(--image-caption-margin);
        background: var(--image-caption-background);
        border: var(--image-caption-border);
        border-radius: var(--image-caption-border-radius);
        box-shadow: var(--image-caption-box-shadow);
        @include with-styled-text(image-caption);
      }

      img {
        height: auto;
        max-width: 100%;
        object-fit: contain;
      }

      .alignleft {
        display: flex;
        flex-direction: column;
        shape-outside: border-box;
        align-items: flex-start;
        float: left;
        max-width: var(--image-align-left-max-width);
        margin: var(--image-align-left-margin);
        shape-margin: var(--image-align-left-shape-margin);
      }

      .aligncenter {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: var(--image-align-center-max-width);
        margin: var(--image-align-center-margin);
      }

      .alignright {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        float: right;
        shape-outside: border-box;
        margin: var(--image-align-right-margin);
        max-width: var(--image-align-right-max-width);
        shape-margin: var(--image-align-right-shape-margin);
      }

      @include respond-to(sm, up) {
        .alignleft {
          max-width: var(--image-align-left-max-width-m, var(--image-align-left-max-width));
          margin: var(--image-align-left-margin-m, var(--image-align-left-margin));
          shape-margin: var(--image-align-left-shape-margin-m, var(--image-align-left-shape-margin));
        }

        .aligncenter {
          max-width: var(--image-align-center-max-width-m, var(--image-align-center-max-width));
          margin: var(--image-align-center-margin-m, var(--image-align-center-margin));
        }

        .alignright {
          max-width: var(--image-align-right-max-width-m, var(--image-align-right-max-width));
          margin: var(--image-align-right-margin-m, var(--image-align-right-margin));
          shape-margin: var(--image-align-right-shape-margin-m, var(--image-align-right-shape-margin));
        }

        figcaption {
          font-size: var(--image-caption-font-size-m);
        }
      }

      @include respond-to(lg, up) {
        .alignleft {
          max-width: var(--image-align-left-max-width-l, var(--image-align-left-max-width));
          margin: var(--image-align-left-margin-l, var(--image-align-left-margin));
          shape-margin: var(--image-align-left-shape-margin-l, var(--image-align-left-shape-margin));
        }

        .aligncenter {
          max-width: var(--image-align-center-max-width-l, var(--image-align-center-max-width));
          margin: var(--image-align-center-margin-l, var(--image-align-center-margin));
        }

        .alignright {
          max-width: var(--image-align-right-max-width-l, var(--image-align-right-max-width));
          margin: var(--image-align-right-margin-l, var(--image-align-right-margin));
          shape-margin: var(--image-align-right-shape-margin-l, var(--image-align-right-shape-margin));
        }

        figcaption {
          font-size: var(--image-caption-font-size-l);
        }
      }
    }

    .clear,
    .wp-block-spacer {
      clear: both;
    }

    .wp-block-table {
      overflow-x: auto;
    }

    .wp-block-embed {
      margin: var(--wp-block-embed-margin);

      iframe {
        height: 100%;
        width: 100%;
        border: none;
      }
    }

    img,
    iframe {
      display: block;
    }

    .split-heading {
      @include with-styled-text(split-heading);
      color: var(--app-split-heading-color, var(--split-heading-colour));

      @include respond-to(sm, up) {
        font-size: var(--split-heading-font-size-m, var(--split-heading-font-size));
      }

      @include respond-to(lg, up) {
        font-size: var(--split-heading-font-size-l, var(--split-heading-font-size));
      }
    }

    .split-emphasis {
      @include with-styled-text(split-emphasis);
      color: var(--app-split-emphasis-color, var(--split-emphasis-colour));

      @include respond-to(sm, up) {
        font-size: var(--split-emphasis-font-size-m, var(--split-emphasis-font-size));
      }

      @include respond-to(lg, up) {
        font-size: var(--split-emphasis-font-size-l, var(--split-emphasis-font-size));
      }
    }

    button {
      --app-custom-btn-icon-display: var(--split-button-display);
    }

    ul.tick-list {
      list-style-type: '\2713\0020';
      margin: var(--split-ul-margin);

      @include respond-to(sm, up) {
        margin: var(--split-ul-margin-m, var(--split-ul-margin));
      }

      @include respond-to(lg, up) {
        margin: var(--split-ul-margin-l, var(--split-ul-margin));
      }
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: var(--app-custom-content-column-gap);
}

/* stylelint-enable */
